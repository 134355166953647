.reservationSectionWrapper {
    width: 100%;
}

.reservationHistoryWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.reservationModalWrapper {
    display: flex;
    min-height: 150px;
    width: 100%;
    margin-top: 2rem;
}


.entryWrapper {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    margin-bottom: 1rem;
}

.timestamp {
    font-size: 12px;
}